import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Since I've recently moved from using markdownRemark to MDX on all my Gatsby Sites, the instructions to some plugins might not be that straightforward to follow. Recently, I've created a `}<a parentName="p" {...{
        "href": "https://theflying.dev"
      }}>{`Digital Garden`}</a>{` and wanted to add a search bar to it. `}</p>
    <p>{`On one of my first Gatsby projects, I had used the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/plugins/@gatsby-contrib/gatsby-plugin-elasticlunr-search/?=search"
      }}>{`Elasticlurn Plugin for Gatsby`}</a>{` to add a search bar to a website. I decided to try and use this plugin since I don't have to use an external service like Algolia.`}</p>
    <h2 {...{
      "id": "installing-and-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installing-and-configuration",
        "aria-label": "installing and configuration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing and Configuration`}</h2>
    <p>{`Installing the plugin is pretty straightforward. You need to run the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install @gatsby-contrib/gatsby-plugin-elasticlunr-search
`}</code></pre>
    <p>{`Then on your gatsby-config.js file, you can add the configuration for the plugin. If you read the README.md, you can see that one of the configuration options is the `}<strong parentName="p">{`resolvers`}</strong>{`, here you need to replace `}<inlineCode parentName="p">{`MarkdownRemark`}</inlineCode>{` for `}<inlineCode parentName="p">{`Mdx`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{` {
      resolve: \`@gatsby-contrib/gatsby-plugin-elasticlunr-search\`,
      options: {
        fields: [\`title\`, \`category\`, \`tags\`, \`excerpt\` ],
        resolvers: {
          Mdx: {
            title: node => node.frontmatter.title,
            category: node => node.frontmatter.category,
            tags: node => node.frontmatter.tags,
            excerpt: node => node.frontmatter.excerpt,
            slug: node => node.fields.slug
          }
        },
        filter: (node, getNode) => node.frontmatter.category
      }
    }
`}</code></pre>
    <p>{`My frontmatter contains all those fields (but the slug). These are the fields that I want users to be able to search. You can choose as many or as little as you want. `}</p>
    <h2 {...{
      "id": "search-bar-component",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#search-bar-component",
        "aria-label": "search bar component permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Search Bar Component`}</h2>
    <p>{`With the plugin installed and configured, we have to create the search bar component. I re-used the component that I had created on the other project. `}</p>
    <p><em parentName="p">{`Note: This component needs refactoring. Most of this code was taken from the plugin instructions.`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React, { Component } from "react"
import { Index } from "elasticlunr"
import { Link } from "gatsby"

export default class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: \`\`,
            results: []
        }
    }

    render() {
        return ( 
            <div className="my-5 relative">
                <div className="flex justify-center items-center">
                    <i className="gg-search mr-2" />
                    <input type="text" value={this.state.query} onChange={this.search} placeholder="Search..." />
                </div>
                {this.state.results.length !== 0 ? 
                    <div className="mt-2 flex flex-col absolute z-10 search-results">
                        {this.state.results.map(page => (
                                <Link className="link m-1" to={\`\${page.slug}\`} key={page.title}>
                                    <span className="underline">{page.title}</span> : <span className="green">{page.tags.join(', ')}</span>
                                </Link>
                                
                        ))}
                    </div>
                : ''}
            </div>
        )
    }

    getOrCreateIndex = () =>
        this.index ? this.index : Index.load(this.props.searchIndex)
    
    search = evt => {
        const query = evt.target.value
        this.index = this.getOrCreateIndex()
        this.setState({
            query,
            results: this.index
                .search(query, { expand: true})
                .map(({ ref }) => this.index.documentStore.getDoc(ref))
        })
    }
}
`}</code></pre>
    <p>{`I want users to get partial matches straight away. So when you type something, you will get all matches first.  Then the query will return fewer results. If you don't want this to happen and return the search results instead, you can remove the `}<inlineCode parentName="p">{`{ expand: true }`}</inlineCode>{` parameter from the `}<inlineCode parentName="p">{`search`}</inlineCode>{` method.`}</p>
    <p>{`You can probably see that on this site I am using `}<a parentName="p" {...{
        "href": "https://tailwindcss.com/"
      }}>{`TailwindCss`}</a>{`. The only bit of CSS that I added outside Tailwind was the `}<inlineCode parentName="p">{`search-results`}</inlineCode>{` which adds some styling(colour, spacing) to that div. The reason why I decided to use an absolute position, is because I want the search results to drop down and stay above the content.`}</p>
    <h2 {...{
      "id": "importing-and-using-the-search-bar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#importing-and-using-the-search-bar",
        "aria-label": "importing and using the search bar permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Importing and Using the Search Bar`}</h2>
    <p>{`Now that the search bar component is completed, you can use it anywhere on your site by importing it. But you also need to provide a `}<inlineCode parentName="p">{`searchIndex`}</inlineCode>{` to the component. Luckily, the plugin adds a query for you to use.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query {
    siteSearchIndex {
        index
    }
}
`}</code></pre>
    <p>{`Then you can import the search bar component and use it like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SearchBar  searchIndex={props.data.siteSearchIndex.index} />
`}</code></pre>
    <p>{`That's all there is to add a search bar with elasticlunr plugin and MDX. If you have any issues feel free to reach me on `}<a parentName="p" {...{
        "href": "https://twitter.com/FabioRosado_"
      }}>{`Twitter @FabioRosado`}</a>{`, I will be happy to give you a hand!`}</p>
    <hr></hr>
    <p><strong parentName="p">{`References:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/plugins/@gatsby-contrib/gatsby-plugin-elasticlunr-search/?=search"
        }}>{`Elasticlurn Plugin for Gatsby`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tailwindcss.com/"
        }}>{`TailwindCss`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:////theflying.dev"
        }}>{`Digital Garden`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      